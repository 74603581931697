import React, { useEffect } from "react";

import PageWrapper from "../components/PageWrapper";
import Hero from "../sections/module/Hero";

import banner from "../assets/image/inner-page/png/modules/academic-module-banner.png";
import Functionalities from "../sections/module/Functionalities";
import {
  BenefitData,
  FeatureData,
  FunctionalitiesData,
} from "../const/static-data";
import Features from "../sections/module/Features";
import Benefits from "../sections/module/Benefits";
import SectionTabs from "../sections/module/SectionTabs";
import ModuleImage from "../sections/module/ModuleImage";

const AdminModule = () => {
  useEffect(() => {
    const currentPath = window.location.pathname;
    const withoutSlashesPath = currentPath.replace(/^\/+|\/+$/g, "").trim();
    const newPath = `/${withoutSlashesPath}/index.html`;
    window.history.replaceState({}, "", newPath);
  }, []);

  return (
    <>
      <PageWrapper
        themeConfig={{
          headerClassName: "site-header--menu-right",
          headerButton: (
            <>
              <button className="btn btn btn-blue-3 header-btn-2 font-size-3 rounded-5">
                Start 14 Days Free Trial
              </button>
            </>
          ),
          footerStyle: "style3",
        }}
      >
        <Hero title={"Academic"} banner={banner} />

        <SectionTabs />

        <section className="mt-15">
          <div className="container">
            <h2 className="font-size-8">About</h2>
            <p id="section2" className="gray-text font-size-6">
              The Head of Academics of your school will have unrestricted access
              to this module to gain scalable information based on the
              institute, batch, academic year, course or any other filter. It
              provides a user centric overview of all information about the
              academic operations of one or more institutions. The academic team
              can view data on interactive calendars, tabs, filters and other
              visualization features to ensure that no operations overlap or
              affect the quality of education in your education center. This
              module is created to allow the administration to create class
              schedules, assign classes and tailor programs based on the demands
              of their prospective students in a centralized manner.
            </p>
          </div>
        </section>
        <Functionalities data={FunctionalitiesData.Admin} />
        <Features data={FeatureData.Admin} />
        <ModuleImage />
        <Benefits data={BenefitData.Admin} />
        {/*<Team />*/}
        {/*<Stats />*/}
      </PageWrapper>
    </>
  );
};
export default AdminModule;
